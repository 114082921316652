import React, { useContext, useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import UserContext from "../../context/UserContext";
import '../../styles/NavBar.css'
const NavBar = () => {
    const { user, Logout } = useContext(UserContext)
    useEffect(() => {
        window.scrollTo(0, 0);
        var nav = document.querySelector('nav');

        window.addEventListener('scroll', function () {
            if (window.pageYOffset > 100) {
                nav.classList.add('bg-color');
                nav.classList.add('shadow');
                //    nav.classList.remove('navbar-transparent', 'shadow');
            } else {
                //      nav.classList.add('navbar-transparent', 'shadow');
                nav.classList.remove('bg-color');
                nav.classList.remove('shadow');
            }
        });
    }, [])
    return (
        <>
            <Navbar className=" text-uppercase fixed-top" expand="md">
                <Container className="container-fluid">
                    <Link to="/" className="navbar-brand">
                        <img src="https://uruapan.gob.mx/wp-content/uploads/2021/10/cropped-Nuevo-Logo2-1-85x85.png" alt="logo" className="img-fluid" />
                    </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">

                            <Link to="/" className="nav-link"> Inicio</Link>
                            <a href="#beneficios" className="nav-link">Beneficios</a>
                            <a href="#contactanos" className="nav-link">Contáctanos</a>
                            {user !== undefined && <button type="button" onClick={() => Logout()} style={{ backgroundColor: "transparent" }} className="border-0 nav-link" >Cerrar sesión</button>}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </>
    )
}

export default NavBar