import React from "react";

const Footer = () =>{
    return(
        <div id = "contactanos" className="footer-bg jumbotron py-4 text-white text-center">
        <h5>Jóvenes del bien</h5><br/>
        <p>Copyright © 2022 | Gobierno de Uruapan | Todos los Derechos Reservados</p>
        </div>
    )
}

export default Footer