//mail regex
const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//curp regex
const curpformat = /^([A-Z&]|[a-z&]{1})([AEIOUX]|[aeioux]{1})([A-Z&]|[a-z&]{1})([A-Z&]|[a-z&]{1})([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([HM]|[hm]{1})([AS|as|BC|bc|BS|bs|CC|cc|CS|cs|CH|ch|CL|cl|CM|cm|DF|df|DG|dg|GT|gt|GR|gr|HG|hg|JC|jc|MC|mc|MN|mn|MS|ms|NT|nt|NL|nl|OC|oc|PL|pl|QT|qt|QR|qr|SP|sp|SL|sl|SR|sr|TC|tc|TS|ts|TL|tl|VZ|vz|YN|yn|ZS|zs|NE|ne]{2})([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([0-9]|[A-Z&]|[a-z&])[0-9]$/gm
const VerifiacarInputs = (itemSeleccionado) => {
    var inputError = ""

    if (itemSeleccionado.curp.length > 0) {
        if (!itemSeleccionado.curp.match(curpformat))
            inputError += "CURP no válido \n"
    }
    else
        inputError += "CURP\n"

    inputError += itemSeleccionado.name.length > 0 ? "" : "Nombre\n"
    inputError += itemSeleccionado.last_name_1.length > 0 ? "" : "Apellido paterno\n"
    inputError += itemSeleccionado.last_name_2.length > 0 ? "" : "Apellido materno\n"
    inputError += itemSeleccionado.phone.length === 10 ? "" : "Teléfono\n"
    inputError += itemSeleccionado.school.length > 0 ? "" : "Escuela\n"
    inputError += itemSeleccionado.age.length > 0 ? "" : "Edad\n"
    inputError += itemSeleccionado.address.length > 0 ? "" : "Domicilio\n"
    
    if (itemSeleccionado.email.length > 0) {
        if (!itemSeleccionado.email.match(mailformat))
            inputError += "Email no válido \n"
    }
    else
        inputError += "Email\n"


    return inputError
}

export {VerifiacarInputs}