import { useContext, useEffect, useState } from "react"
import { doc, setDoc } from '@firebase/firestore';
import { db } from "../../firebaseConfig/FirebaseConfig";
import Modal from "react-bootstrap/Modal";
import ScanBarCode from "./ScanBarCode";
import { AddNewCardNumber, GetCurrentCardNumber, sumarCardNumber } from "./ManageCardNumber";
import { VerifiacarInputs } from "../general/VerificarInputs";
import UserContext from "../../context/UserContext";

const SimpleAdmin = () => {
    const [modalInsertar, setModalInsertar] = useState(false)
    const OpenCLoseModalInsertar = () => {
        setModalInsertar(!modalInsertar)
    }
    const HandleChange = e => {
        const { name, value } = e.target
        setitemSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))
        //console.log(itemSeleccionado)
    }

    const [itemSeleccionado, setitemSeleccionado] = useState({
        curp: "",
        name: "",
        last_name_1: "",
        last_name_2: "",
        phone: "",
        school: "",
        age: "",
        address: "",
        email: "",
        card: ""
    })
    const [dBCardNumber, setDBCardNumber] = useState(undefined)
    useEffect(() => {
        GetCurrentCardNumber(setDBCardNumber)
    }, [])
    const { user } = useContext(UserContext)
    const PostUsuario = async () => {

        const userInputData = VerifiacarInputs(itemSeleccionado)
        if (userInputData === "") {

            await setDoc(doc(db, "users", itemSeleccionado.curp),
                {
                    curp: itemSeleccionado.curp,
                    name: itemSeleccionado.name,
                    last_name_1: itemSeleccionado.last_name_1,
                    last_name_2: itemSeleccionado.last_name_2,
                    phone: itemSeleccionado.phone,
                    school: itemSeleccionado.school,
                    address: itemSeleccionado.address,
                    email: itemSeleccionado.email,
                    card: user.data.cardNumberId + dBCardNumber.company + dBCardNumber.product,
                    age: itemSeleccionado.age
                }).then(() => {
                    alert("¡Usuario creado correctamente!");
                })
                .catch(err => {
                    alert("¡Error al crear el usuario!, por favor contacta a tu administrador");
                });
            OpenCLoseModalInsertar()
            AddNewCardNumber(sumarCardNumber(dBCardNumber.product))
            GetCurrentCardNumber(setDBCardNumber)
        }
        else {
            // ObtenerCostoDeEnvio()
            alert("Verifica los siguientes campos:\n" + userInputData)
        }

    }
    return (
        <>

            <div className="container">

                <ScanBarCode />

                <div className="bg-white rounded-3 text-center mb-5 border">
                    <h1>Usuarios<button className="btn text-white btn-primary m-5" onClick={() => OpenCLoseModalInsertar()}>Nuevo usuario</button></h1>
                </div>
            </div>
            <Modal
                show={modalInsertar}
                onHide={() => OpenCLoseModalInsertar()}>
                <Modal.Header closeButton>Nuevo Usuario</Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label className="form-label">CURP</label>
                        <br />
                        <input className="form-control mb-3" type="text" name="curp" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Nombre</label>
                        <br />
                        <input className="form-control mb-3" type="text" name="name" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Apellido paterno</label>
                        <br />
                        <input className="form-control mb-3" type="text" name="last_name_1" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Apellido materno</label>
                        <br />
                        <input className="form-control mb-3" type="text" name="last_name_2" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Teléfono</label>
                        <br />
                        <input className="form-control mb-3" type="text" name="phone" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Escuela</label>
                        <br />
                        <input className="form-control mb-3" type="text" name="school" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Edad</label>
                        <br />
                        <input className="form-control mb-3" type="text" name="age" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Domicilio</label>
                        <br />
                        <input className="form-control mb-3" type="text" name="address" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">email</label>
                        <br />
                        <input className="form-control mb-3" type="text" name="email" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Tarjeta No.</label>
                        <br />
                        {dBCardNumber && <input className="form-control mb-3" disabled type="text" name="card" value={user.data.cardNumberId.toString() + dBCardNumber.company + dBCardNumber.product}></input>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => PostUsuario()} className="btn btn-primary">Aceptar</button>
                    <button onClick={() => OpenCLoseModalInsertar()} className="btn btn-danger">Cancelar</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SimpleAdmin