import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig/FirebaseConfig";

const GetCurrentCardNumber = async (setCardNumber) => {

    const docRef = doc(db, "barcode", "barcode");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        //"750 87654 00000"
        //console.log("Document data:", docSnap.data());

        setCardNumber({ country: docSnap.data().country, company: docSnap.data().company, product: docSnap.data().product })

    } else {
        // doc.data() will be undefined in this case
        setCardNumber(undefined)
    }


}

const sumarCardNumber = (number) => {
    let newNumber = parseInt(number) + 1

    newNumber = newNumber.toString()

    while (newNumber.length < 6)
        newNumber = "0" + newNumber

    return newNumber
}

const AddNewCardNumber = async (newCardNumber) => {
    await updateDoc(doc(db, "barcode", "barcode"), {
        product: newCardNumber
    });
}
export { AddNewCardNumber, GetCurrentCardNumber, sumarCardNumber }