import React, { useContext } from 'react'
import NavBar from '../components/general/NavBar'
import LoginComponent from '../components/admin/LoginComponent'
import SuperAdmin from '../components/admin/SuperAdmin'
import UserContext from '../context/UserContext'
import SimpleAdmin from '../components/admin/SimpleAdmin'
import MiddleAdmin from '../components/admin/MiddleAdmin'

const Admin = () => {
    const { user } = useContext(UserContext)
    return (
        <>
            <NavBar />

            {user === undefined ?
                <div className="main-color-image">
                    <div className="main-banner-image w-100  d-flex justify-content-center align-items-center">
                        <LoginComponent />
                    </div>
                </div>
                :
                <div className="main-color-image">
                    <div className="main-banner-image w-100  d-flex justify-content-center align-items-center">

                      {user.data.type === "super" && <SuperAdmin/>}
                      {user.data.type === "simple" && <SimpleAdmin/>}
                      {user.data.type === "middle" && <MiddleAdmin />}
                    </div>
                </div>
            }

        </>
    )
}

export default Admin