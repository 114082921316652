import React, { useEffect } from "react"
import { Container } from "react-bootstrap"
import Footer from "../components/general/Footer"
import LoginComponent from "../components/admin/LoginComponent"
import NavBar from "../components/general/NavBar"
import '../styles/Main.css'
import ListaSitios from "../components/general/ListaSitios"
const Main = () => {

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    return (
        <>
            <NavBar />
            <div className="main-banner-image w-100  d-flex justify-content-center align-items-center">
                <div className="text-white">
                    <h1>Jóvenes del bien</h1>
                </div> 
            </div>
            <Container id="beneficios" className=" my-5">
                <ListaSitios/>
            </Container>
            <Footer/>
        </>
    )
}

export default Main