import React from "react";
import { Col, Row, Table } from 'react-bootstrap';
import BeneficiosCard from "./BeneficiosCard";
const ListaSitios = () => {

    const data = [
        {
            name: "Unidad Deportiva Hnos López Rayon",
            beneficio: "Costo de entrada gratuito",
            location: "https://g.page/unidadeportivaupn?share",
            logo:"5"
        },
        {
            name: "Presa Caltzontzin",
            beneficio: "Costo de entrada gratuito",
            location: "https://goo.gl/maps/1Zia3PDsyN13kHsj7",
            logo:"15"
        },
        {
            name: "La Pinera",
            beneficio: "Costo de entrada gratuito",
            location: "https://goo.gl/maps/6GrVkJ9xA5wVicGW9",
            logo:"14"
        },
        {
            name: "Transporte público  \"Urbanos\"",
            beneficio: "Descuento de 10%",
            logo:"17"
        }
        ,
        {
            name: "Central de Autobuses Uruapan",
            beneficio: "Descuento de $50",
            location: "https://g.page/central-de-autobuses-uruapan?share",
            logo:"16"
        },
        {
            name: "Inscripción universidades públicas",
            beneficio: "Descuento de 8%",
            logo:"4"
        },
        {
            name: "Inscripción preparatorias públicas",
            beneficio: "Descuento de 6%",
            logo:"4"
        }
    ]

    return (
        <>
            <div className=" rounded-3 p-4 ">
                <div className="text-center mb-5">
                    <h4 className="mb-5">Lista de beneficios</h4>
                </div>

                {data !== undefined &&
                    <Row xs={1} md={2} className="g-3">
                        {data.map((item, idx) => (
                            <Col key={"beneficio" + idx}>
                                <BeneficiosCard logo={item.logo} title={item.name} direccion={item.location} map={item.location} txt={item.beneficio} />
                            </Col>
                        ))}
                    </Row>
                }
            </div>
        </>
    )
}

export default ListaSitios