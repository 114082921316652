import Main from "./pages/Main";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Admin from "./pages/Admin";
import { UserState } from "./context/UserState";
function App() {
  return (

    <Router>
      <UserState>
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/admin" element={<Admin />} />
        </Routes>
      </UserState>
    </Router>


  );
}

export default App;
