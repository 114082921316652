import React, { useContext, useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal'
import { db } from '../../firebaseConfig/FirebaseConfig'
import { collection, deleteDoc, updateDoc, doc, getDocs, setDoc } from '@firebase/firestore';
import 'table2excel';
import ScanBarCode from "./ScanBarCode";
import { AddNewCardNumber, GetCurrentCardNumber, sumarCardNumber } from "./ManageCardNumber";
import { VerifiacarInputs } from '../general/VerificarInputs'
import MovimientosCRUD from "./MoviminetosCRUD";
import UserContext from "../../context/UserContext";

const SuperAdmin = () => {
    const Table2Excel = window.Table2Excel;
    const [data, setData] = useState(undefined)
    //manejadores para mostrar y ocultar los modales
    const [modalInsertar, setModalInsertar] = useState(false)
    const [modalEditar, setModalEditar] = useState(false)
    const [modalEliminar, setModalEliminar] = useState(false);
    //objeto para identificar con cual item de la lista estamos
    //trabajando de manera especifica
    const [itemSeleccionado, setitemSeleccionado] = useState({
        curp: "",
        name: "",
        last_name_1: "",
        last_name_2: "",
        phone: "",
        school: "",
        age: "",
        address: "",
        email: "",
        card: ""
    })
    const [editItemSeleccionado, setEditItemSeleccionado] = useState(undefined)
    const [dBCardNumber, setDBCardNumber] = useState(undefined)
    //se activan en el on click de los botones
    //insertar, eliminar, editar correspondientemente
    const OpenCLoseModalInsertar = () => {
        setModalInsertar(!modalInsertar)
    }
    const OpenCLoseModalEliminar = () => {
        setModalEliminar(!modalEliminar)
    }
    const OpenCLoseModalEditar = () => {
        setModalEditar(!modalEditar)

    }
    const HandleChange = e => {
        const { name, value } = e.target
        setitemSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))
        //console.log(itemSeleccionado)
    }

    //Metodo get para obtener los items de la DB
    const GetItems = async () => {
        document.getElementById("showUsersBtn").hidden = true
        const querySnapshot = await getDocs(collection(db, "users"));
        setData(querySnapshot.docs)
    }

    useEffect(() => {
        GetCurrentCardNumber(setDBCardNumber)
    }, [])

    const { user } = useContext(UserContext)
    const PostUsuario = async () => {

        const userInputData = VerifiacarInputs(itemSeleccionado)
        if (userInputData === "") {

            await setDoc(doc(db, "users", itemSeleccionado.curp),
                {
                    curp: itemSeleccionado.curp,
                    name: itemSeleccionado.name,
                    last_name_1: itemSeleccionado.last_name_1,
                    last_name_2: itemSeleccionado.last_name_2,
                    phone: itemSeleccionado.phone,
                    school: itemSeleccionado.school,
                    address: itemSeleccionado.address,
                    email: itemSeleccionado.email,
                    card: user.data.cardNumberId + dBCardNumber.company + dBCardNumber.product,
                    age: itemSeleccionado.age
                }).then(() => {
                    alert("¡Usuario creado correctamente!");
                })
                .catch(err => {
                    alert("¡Error al crear el usuario!, por favor contacta a tu administrador");
                });
            GetItems()
            OpenCLoseModalInsertar()
            AddNewCardNumber(sumarCardNumber(dBCardNumber.product))
            GetCurrentCardNumber(setDBCardNumber)
        }
        else {
            // ObtenerCostoDeEnvio()
            alert("Verifica los siguientes campos:\n" + userInputData)
        }

    }
    const EditProducto = async () => {
        const ref = doc(db, "users", itemSeleccionado.curp);
        const userInputData = VerifiacarInputs(itemSeleccionado)
        if (userInputData === "") {

            await updateDoc(ref, {
                curp: itemSeleccionado.curp,
                name: itemSeleccionado.name,
                last_name_1: itemSeleccionado.last_name_1,
                last_name_2: itemSeleccionado.last_name_2,
                phone: itemSeleccionado.phone,
                school: itemSeleccionado.school,
                address: itemSeleccionado.address,
                email: itemSeleccionado.email,
                age: itemSeleccionado.age,

            });

            GetItems()
            OpenCLoseModalEditar()
            alert("¡Usuario editado correctamente!");
        } else {
            // ObtenerCostoDeEnvio()
            alert("Verifica los siguientes campos:\n" + userInputData)
        }
    }
    const DeleteProducto = async () => {
        await deleteDoc(doc(db, "users", editItemSeleccionado.data().curp));
        GetItems()
        OpenCLoseModalEliminar()
        alert("¡Usuario eliminado correctamente!")

    }

    // se activa en el onclick de los botones eliminar y editar abriendo el modal
    //correspondiente
    const SeleccionarUsuario = (item, caso) => {
        setitemSeleccionado(item.data());
        setEditItemSeleccionado(item);

        (caso === "editar") ?
            OpenCLoseModalEditar()
            :
            OpenCLoseModalEliminar()
    }

    const ExportToExcel = () => {
        const table2excel = new Table2Excel();
        table2excel.export(document.querySelectorAll("#table"));
    }
    return (
        <div className="container">

            <ScanBarCode />

            <div className="bg-white rounded-3 text-center mb-5 border">
                <h1>Usuarios<button className="btn text-white btn-primary m-5" onClick={() => OpenCLoseModalInsertar()}>Nuevo usuario</button></h1>
                <button id="showUsersBtn" className="btn text-white bg-secondary m-5" onClick={() => GetItems()}>Ver usuarios</button>
                <Modal
                    show={modalInsertar}
                    onHide={() => OpenCLoseModalInsertar()}>
                    <Modal.Header closeButton>Nuevo Usuario</Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label className="form-label">CURP</label>
                            <br />
                            <input className="form-control mb-3" type="text" name="curp" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Nombre</label>
                            <br />
                            <input className="form-control mb-3" type="text" name="name" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Apellido paterno</label>
                            <br />
                            <input className="form-control mb-3" type="text" name="last_name_1" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Apellido materno</label>
                            <br />
                            <input className="form-control mb-3" type="text" name="last_name_2" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Teléfono</label>
                            <br />
                            <input className="form-control mb-3" type="text" name="phone" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Escuela</label>
                            <br />
                            <input className="form-control mb-3" type="text" name="school" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Edad</label>
                            <br />
                            <input className="form-control mb-3" type="text" name="age" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Domicilio</label>
                            <br />
                            <input className="form-control mb-3" type="text" name="address" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">email</label>
                            <br />
                            <input className="form-control mb-3" type="text" name="email" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Tarjeta No.</label>
                            <br />
                            {dBCardNumber && <input className="form-control mb-3" disabled type="text" name="card" value={user.data.cardNumberId.toString() + dBCardNumber.company + dBCardNumber.product}></input>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={() => PostUsuario()} className="btn btn-primary">Aceptar</button>
                        <button onClick={() => OpenCLoseModalInsertar()} className="btn btn-danger">Cancelar</button>
                    </Modal.Footer>
                </Modal>
                {data !== undefined ?
                    <>
                        <Table className="" id="table" striped bordered hover responsive>
                            <thead>
                                <tr className="align-middle">
                                    <th>CURP</th>
                                    <th>Nombre</th>
                                    <th>Apellido paterno</th>
                                    <th>Apellido materno</th>
                                    <th>Teléfono</th>
                                    <th>Escuela</th>
                                    <th>Edad</th>
                                    <th>Domicilio</th>
                                    <th>Email</th>
                                    <th>Tarjeta No.</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody className="align-middle">
                                {data.map((item, idx) => (
                                    <tr key={"user" + idx}>
                                        <td>{item.data().curp}</td>
                                        <td>{item.data().name}</td>
                                        <td>{item.data().last_name_1}</td>
                                        <td>{item.data().last_name_2}</td>
                                        <td> <input className="bg-transparent border-0 text-center" type="text" readOnly value={item.data().phone} /></td>
                                        <td>{item.data().school}</td>
                                        <td>{item.data().age}</td>
                                        <td><input className="bg-transparent border-0 text-center" type="text" readOnly value={item.data().address} /></td>
                                        <td>{item.data().email}</td>
                                        <td><input className="bg-transparent border-0 text-center" type="text" readOnly value={item.data().card} /></td>
                                        <td>
                                            <button className="btn btn-primary m-1" onClick={() => SeleccionarUsuario(item, "editar")}>Editar</button>
                                            <button className="btn btn-danger" onClick={() => SeleccionarUsuario(item, "eliminar")}>Eliminar</button>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </Table>
                        <div>
                            <input onClick={() => ExportToExcel()} type="button" className="btn btn-success my-4" value="Descargar tabla" />
                        </div>

                        <Modal
                            show={modalEditar}
                            onHide={() => OpenCLoseModalEditar()}>
                            <Modal.Header closeButton>Editar usuario</Modal.Header>
                            <Modal.Body>
                                <div className="form-group">
                                    <label className="form-label">CURP</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.curp} className="form-control mb-3" type="text" name="curp" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Nombre</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.name} className="form-control mb-3" type="text" name="name" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Apellido paterno</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.last_name_1} className="form-control mb-3" type="text" name="last_name_1" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Apellido materno</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.last_name_2} className="form-control mb-3" type="text" name="last_name_2" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Teléfono</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.phone} className="form-control mb-3" type="text" name="phone" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Escuela</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.school} className="form-control mb-3" type="text" name="school" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Edad</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.age} className="form-control mb-3" type="text" name="age" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Domicilio</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.address} className="form-control mb-3" type="text" name="address" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">email</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.email} className="form-control mb-3" type="text" name="email" onChange={HandleChange}></input>
                                    <br />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button type="submit" onClick={() => EditProducto()} className="btn btn-primary">Editar</button>
                                <button onClick={() => OpenCLoseModalEditar()} className="btn btn-danger">Cancelar</button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={modalEliminar}
                            onHide={() => OpenCLoseModalEliminar()}>
                            <Modal.Header closeButton>Eliminar usuario</Modal.Header>
                            <Modal.Body>
                                <div className="text-center">
                                    <p>¿Estás seguro que deseas eliminar el usuario {editItemSeleccionado && editItemSeleccionado.data().name}?</p>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button onClick={() => DeleteProducto()} className="btn btn-danger">Sí</button>
                                <button onClick={() => OpenCLoseModalEliminar()} className="btn btn-primary">No</button>
                            </Modal.Footer>
                        </Modal>
                    </> :
                    <>
                        <div className="text-center mt-4">
                          
                        </div>
                    </>
                }
            </div>
            <MovimientosCRUD />
        </div>
    )
}

export default SuperAdmin