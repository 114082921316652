import logo1 from '../../assets/Beneficios/1.png'
import logo2 from '../../assets/Beneficios/2.png'
import logo3 from '../../assets/Beneficios/3.png'
import logo4 from '../../assets/Beneficios/4.png'
import logo5 from '../../assets/Beneficios/5.png'
import logo6 from '../../assets/Beneficios/6.png'
import logo7 from '../../assets/Beneficios/7.png'
import logo8 from '../../assets/Beneficios/8.png'
import logo9 from '../../assets/Beneficios/9.png'
import logo10 from '../../assets/Beneficios/10.png'
import logo11 from '../../assets/Beneficios/11.png'
import logo12 from '../../assets/Beneficios/12.png'
import logo13 from '../../assets/Beneficios/13.png'
import logo14 from '../../assets/Beneficios/14.png'
import logo15 from '../../assets/Beneficios/15.png'
import logo16 from '../../assets/Beneficios/16.png'
import logo17 from '../../assets/Beneficios/17.png'
import logo18 from '../../assets/Beneficios/18.png'

const ShowLogosBeneficios = ({logoNumber}) =>{
    
    return(
        <>
        {logoNumber === "1" && <img className="rounded-circle border img-fluid" alt = "..." src = {logo1}/> }
        {logoNumber === "2" && <img className="rounded-circle border img-fluid" alt = "..." src = {logo2}/> }
        {logoNumber === "3" && <img className="rounded-circle border img-fluid" alt = "..." src = {logo3}/> }
        {logoNumber === "4" && <img className="rounded-circle border img-fluid" alt = "..." src = {logo4}/> }
        {logoNumber === "5" && <img className="rounded-circle border img-fluid" alt = "..." src = {logo5}/> }
        {logoNumber === "6" && <img className="rounded-circle border img-fluid" alt = "..." src = {logo6}/> }
        {logoNumber === "7" && <img className="rounded-circle border img-fluid" alt = "..." src = {logo7}/> }
        {logoNumber === "8" && <img className="rounded-circle border img-fluid" alt = "..." src = {logo8}/> }
        {logoNumber === "9" && <img className="rounded-circle border img-fluid" alt = "..." src = {logo9}/> }
        {logoNumber === "10" && <img className="rounded-circle border img-fluid" alt = "..." src = {logo10}/> }
        {logoNumber === "11" && <img className="rounded-circle border img-fluid" alt = "..." src = {logo11}/> }
        {logoNumber === "12" && <img className="rounded-circle border img-fluid" alt = "..." src = {logo12}/> }
        {logoNumber === "13" && <img className="rounded-circle border img-fluid" alt = "..." src = {logo13}/> }
        {logoNumber === "14" && <img className="rounded-circle border img-fluid" alt = "..." src = {logo14}/> }
        {logoNumber === "15" && <img className="rounded-circle border img-fluid" alt = "..." src = {logo15}/> }
        {logoNumber === "16" && <img className="rounded-circle border img-fluid" alt = "..." src = {logo16}/> }
        {logoNumber === "17" && <img className="rounded-circle border img-fluid" alt = "..." src = {logo17}/> }
        {logoNumber === "18" && <img className="rounded-circle border img-fluid" alt = "..." src = {logo18}/> }

        </>
    )

}

export default ShowLogosBeneficios