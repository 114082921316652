import React, { useContext, useState } from "react"
import { Alert, Card, Form } from "react-bootstrap"
import { Link } from "react-router-dom"
import UserContext from "../../context/UserContext"

const LoginComponent = () => {

    const [datosIngresados, setDatosIngresados] = useState({
        user: "",
        password: ""
    })

    const HandleChange = e => {
        const { name, value } = e.target
        setDatosIngresados((prevState) => ({
            ...prevState,
            [name]: value
        }))
        //console.log(datosIngresados)
    }
    // const auth = getAuth();

    const { Login, loginError } = useContext(UserContext)

    const ClickLogin = () => {

        Login(datosIngresados.user, datosIngresados.password)

    }
    return (
        <>

            <Card  style={{ minWidth: "250px" }} className="mt-5 w-25">
                <Card.Body>
                    <Card.Title className="text-center">
                        <img  src="https://uruapan.gob.mx/wp-content/uploads/2021/10/cropped-Nuevo-Logo2-1-85x85.png" alt="logo" className="img-fluid" />
                        <br />
                        Jóvenes del bien</Card.Title>
                    {
                        loginError ?
                            <Alert variant="danger" className="my-2">
                                Usuario o contraseña incorrectos
                            </Alert>
                            :
                            <></>
                    }

                    <Form.Group className="mb-4" controlId="user">
                        <Form.Label>Usuario</Form.Label>
                        <Form.Control onChange={HandleChange} name="user" type="email" value={datosIngresados && datosIngresados.user} />
                    </Form.Group>

                    <Form.Group className="mb-4" controlId="password">
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control onChange={HandleChange} name="password" type="password" value={datosIngresados && datosIngresados.password} />
                    </Form.Group>
                    <input className="btn w-100 btn-primary text-white mb-4" type="button" onClick={() => ClickLogin()} value="Ingresar"></input>

                </Card.Body>
            </Card>
        </>
    )
}
export default LoginComponent